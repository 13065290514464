@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #1A76D1;
  --secondary: #374151;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #4d5a70;
}

@font-face {
  font-family: 'Inter';
  src: url('./lib/font/Inter-VariableFont_slnt\,wght.ttf');
}

body {
  font-family: 'Inter';
  margin: 0;
  background-color: var(--secondary);
}